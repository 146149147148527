/**
 * Created by osirvent on 24/02/2016.
 */
//TODO: En la optimització s'ha decidit de no fer la part de carpeta
angular
    .module('annexaApp')
    .factory('DocumentsViewModesFactory',['$q', 'RestService', '$http', 'GlobalDataFactory', function($q, RestService, $http, GlobalDataFactory){
        var factory = {};

        factory.documentsViewModeTypes = [];

        factory.getDocuments = function(type, query, page) {
            var deferrend = $q.defer();

            $http({
                url: './api/documentsviewmode/documents',
                method: "GET",
                params: { type: type, filterCall: query.filterCall, filterCallAux: query.filterCallAux, page: page, size: 20 }
            }).success(function(res) {
                deferrend.resolve(JSOG.decode(res));
            }).error(function (msg, code) {
                deferrend.reject(msg);
            });

            return deferrend.promise;
        }

        factory.getRootDocuments = function(type, query, page) {
            var deferrend = $q.defer();

            $http({
                url: './api/documentsviewmode/documents/root',
                method: "GET",
                params: { type: type, filterCall: query.filterCall, filterCallAux: query.filterCallAux, page: page, size: 20 }
            }).success(function(res) {
                deferrend.resolve(JSOG.decode(res));
            }).error(function (msg, code) {
                deferrend.reject(msg);
            });

            return deferrend.promise;
        }

        factory.getDocumentViewModeTypes = function() {
            factory.documentsViewModeTypes = GlobalDataFactory.documentViewModeTypes;

            return true;
        };

        factory.getDocumentViewModeRootValue = function(id) {
            var deferrend = $q.defer();

            $http({
                url: './api/documentsviewmode/root',
                method: "GET",
                params: { type: id }
            }).success(function (res) {
                deferrend.resolve(JSOG.decode(res));
            }).error(function(msg,code){
                deferrend.reject(msg);
            });

            return deferrend.promise;
        };

        factory.newContainer = function (type, parent, name) {
            var deferrend = $q.defer();

            $http({
                url: './api/documentsviewmode/container',
                method: "POST",
                params: { type: type, parent: parent, name: name}
            }).success(function (res) {
                deferrend.resolve(JSOG.decode(res));
            }).error(function(msg, code) {
                deferrend.reject(msg);
            });

            return deferrend.promise;
        };

        factory.moveDocument = function (source, target, document) {
            var deferrend = $q.defer();

            $http({
                url: './api/documentsviewmode/documents/move',
                method: "POST",
                params: { source: source, target: target, id: document }
            }).success(function(res) {
                deferrend.resolve(res);
            }).error(function (msg, code) {
                deferrend.reject(msg);
            });

            return deferrend.promise;
        }
        return factory;
    }]);