/**
 * Created by osirvent on 15/03/2016.
 */
angular
    .module('annexaApp')
    //.service('DccumentsFactory',['$q', '$http', 'AnnexaPermissionsFactory', 'HelperService', '$rootScope', 'dialogs', '$filter', 'RestService', 'AnnexaEntityFactory', 'Language', 'globalModals', 'AnnexaModalFactory', function($q, $http, AnnexaPermissionsFactory, HelperService, $rootScope, dialogs, $filter, RestService, AnnexaEntityFactory, Language, globalModals, AnnexaModalFactory) {
    .service('DccumentsFactory',['$q', '$http', 'AnnexaPermissionsFactory', 'HelperService', '$rootScope', '$uibModal', 'dialogs', '$filter', 'RestService', 'Language', 'globalModals', 'AnnexaModalFactory', 'CustomFieldFactory', 'AnnexaFormlyFactory', '$state', 'CommonService', 'WizardHandler', 'DialogsFactory', '$timeout', 'GlobalDataFactory', 'apiAdmin', function($q, $http, AnnexaPermissionsFactory, HelperService, $rootScope, $uibModal, dialogs, $filter, RestService, Language, globalModals, AnnexaModalFactory, CustomFieldFactory, AnnexaFormlyFactory, $state, CommonService, WizardHandler, DialogsFactory, $timeout, GlobalDataFactory, apiAdmin) {

        var factory = {};

        //region Globals

        factory.type = undefined;
        factory.selectedDocumentsByType = undefined;
        factory.customFiels = [];
        factory.statuses = [];
        factory.document = {};
        factory.documentoEni = {};
        factory.profilesUser = [];
        factory.sectionsUser = [];
        factory.documentRegisterEntries = [];
        factory.documentDossiers = [];
        factory.selectedType = { type: undefined };
        factory.selectedDocumentStatus = undefined;
        factory.archiveClassifications = [];
        factory.isAuthenticCopy = false;

        //endregion

        //region Router (OK, Repasat)

        factory.setSelectedDocumentStatus = function(status) {
            factory.selectedDocumentStatus = status;
        }

        factory.setSelectedType = function(type) {
            if(!type) {
                factory.selectedType = { type: undefined };
            } else {
                factory.selectedType = type;
            }
        }

        factory.getDocument = function(id){
            var promises = [];

            promises.push($http({ url: './api/doc/' + id, method: 'GET' }));
            promises.push($http({ url: './api/doc/' + id + '/related/register_entries', method: 'GET' }));
            promises.push($http({ url: './api/doc/' + id + '/related/dossier_transaction_documents', method: 'GET' }));
            promises.push($http({ url: './api/doc/' + id + '/check_document_scanner', method: 'GET'}));

            return $q.all(promises)
                .then(function(data) {
                    factory.document = JSOG.decode(data[0].data);
                    factory.documentRegisterEntries = JSOG.decode(data[1].data);
                    factory.documentDossiers = [];
                    _.forEach(JSOG.decode(data[2].data), function (item) {
                        factory.documentDossiers.push(item.dossierTransaction.dossier);
                    });
                    factory.isAuthenticCopy = JSOG.decode(data[3].data);
                }).catch(function(error) {
                    factory.document = undefined;
                    factory.documentRegisterEntries = [];
                    factory.documentDossiers = [];
                    factory.isAuthenticCopy = undefined;
            });
        };

        factory.getDocumentLists = function() {
            if($rootScope.app.configuration && $rootScope.app.configuration.general_document_type &&$rootScope.app.configuration.general_document_type.value) {
                factory.typeDefault = $linq(GlobalDataFactory.documentTypes).singleOrDefault(undefined, "x => x.id == " + $rootScope.app.configuration.general_document_type.value);
            }else{
                factory.typeDefault = undefined;
            }

            factory.profilesUser = AnnexaPermissionsFactory.getSingleProfileDual('new_document', undefined, true);

            factory.sectionsUser = [];
            if ($rootScope.LoggedUser.userSections && $rootScope.LoggedUser.userSections.length) {
                _.forEach($rootScope.LoggedUser.userSections, function (value) {
                    factory.sectionsUser.push(value.section);
                });
            }

            factory.statuses = GlobalDataFactory.documentStatuses;

            if($linq(factory.statuses).count("x => x.id == ''") == 0) {
                factory.statuses.unshift({
                    id: '',
                    icon: 'minus',
                    style: 'black',
                    name: 'global.literals.all',
                    orderStatus: 0
                });
            }

            return true;
        }

        factory.getDocumentoEni = function(id) {
            var deferred = $q.defer();

            $http({
                method: 'GET',
                url: './api/doc/documentoEni/' + id 
            }).success(function(data, status) {
                deferred.resolve(JSOG.decode(data));
            }).error(function(msg,code) {
                deferred.reject(msg);
            });
            return deferred.promise;
        }

        factory.getDocumentsTypeType = function (type) {
            if(GlobalDataFactory.documentTypes && GlobalDataFactory.documentTypes != 0) {
                factory.type = $linq(GlobalDataFactory.documentTypes).singleOrDefault(undefined, " x => x.id == " + type);

                if(factory.type) {
                    return $q.resolve(true);
                } else {
                    $timeout(function() { $state.go('annexa.dashboard'); });
                    return $q.reject("No document type");
                }
            } else {
                return RestService.findOne('DocumentType', type)
                    .then(function (data) {
                        factory.type = JSOG.decode(data.data);
                    }).catch(function(error){});
            }
        }

        factory.getDocumentsTypeFilterColumnCustomFields = function (type) {
            factory.customFiels = [];

            return RestService.findAll('CustomField')
                .then(function(data) {
                    factory.customFiels = JSOG.decode(data.data);
                }).catch(function (error) {
            });
        }

        //endregion

        //region DocumentViewMode

        factory.getDocumentViewModeElementType = function(mime) {
            var retType = 'unknown';

            switch (mime) {
                case 'application/msword':
                case 'application/rtf':
                case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
                case 'application/vnd.oasis.opendocument.text':
                    retType = 'word';
                    break;
                case 'application/pdf':
                    retType = 'pdf';
                    break;
                case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
                    retType = 'powerpoint';
                    break;
                case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
                    retType = 'excel';
                    break;
                case 'text/plain':
                case 'text/xml':
                case 'text/x-sql':
                    retType = 'text';
                    break;
            }

            return retType;
        };

        factory.getDocumentViewModeElementIcon = function(type) {
            var fileType = "fa-file-o";
            var fileName = "global.fileTypes.unknown";

            switch (type) {
                case 'folder':
                    fileType = "fa-folder";
                    fileName = "global.fileTypes.folder";
                    break;
                case 'excel':
                    fileType = "fa-file-excel-o";
                    fileName = "global.fileTypes.excel";
                    break;
                case 'pdf':
                    fileType = 'fa-file-pdf-o';
                    fileName = "global.fileTypes.pdf";
                    break;
                case 'word':
                    fileType = "fa-file-word-o";
                    fileName = "global.fileTypes.word";
                    break;
                case 'image':
                    fileType = "fa-file-image-o";
                    fileName = "global.fileTypes.image";
                    break;
                case 'text':
                    fileType = "fa-file-text-o";
                    fileName = "global.fileTypes.text";
                    break;
                case 'audio':
                    fileType = "fa-file-audio-o";
                    fileName = "global.fileTypes.audio";
                    break;
                case 'powerpoint':
                    fileType = "fa-file-powerpoint-o";
                    fileName = "global.fileTypes.powerpoint";
                    break;
                case 'video':
                    fileType = "fa-file-video-o";
                    fileName = "global.fileTypes.video";
                    break;
            }

            return [fileType, fileName];
        };

        //endregion

        //region Modal Nou Document (OK, Repasat)

        //region typeSelect

        var showFileTemplate = function(value) {
            return value == 'NOT_REQUIRED' ? false : true;
        }

        factory.typeSelected = function(item, model, fields, scope, optionAux) {
            if(model.templateDefaultBooks && model.templateDefaultBooks.$selected && model.templateDefaultBooks.$selected.id){
            	model.template = model.templateDefaultBooks;
            }else{
            	model.template = {};
            }

            var typeSelected = $linq(GlobalDataFactory.documentTypes).singleOrDefault(undefined, "x => x.id == " + item.id);
            if(optionAux && optionAux.length > 0){
            	typeSelected = $linq(optionAux).firstOrDefault(undefined, "x => x.id == " + item.id);
            }

            if(typeSelected) {
                model.showFile = showFileTemplate(typeSelected.documentFileAttachment);
                model.showTemplate = showFileTemplate(typeSelected.documentTemplateSelection);
            }

            if(model.templateDefaultBooks && model.templateDefaultBooks.$selected && model.templateDefaultBooks.$selected.id){
            	model.inputType = 'template';
            }else{
            	model.inputType = !model.showFile && model.showTemplate ? 'template' : 'file';
            }

            if(!(model.templateDefaultBooks && model.templateDefaultBooks.$selected && model.templateDefaultBooks.$selected.id) && $rootScope.esetMode && !(optionAux && optionAux.length > 0)  && (model.module == 'REGENTRADA' || model.module == 'REGSALIDA')) {
                model.showFile = true;
                model.showTemplate = false;
                model.inputType = 'file';
            }

            //Templates
            var indexFieldTemplate = $linq(fields).indexOf("x => x.key == 'template'");
            if(indexFieldTemplate != -1) {
                fields[indexFieldTemplate].templateOptions.options = factory.getTemplateOptions(model);
                $rootScope.$broadcast('treeTemplatesChange');
                if (fields[indexFieldTemplate].formControl) {
                    fields[indexFieldTemplate].formControl.$validate();
                }
            }

            //Archive Classification
            factory.setFileArchiveClassification(model);
        }

        //endregion

        //region document - template

        factory.hideDocumentTemplate = function(model) {
            var typeSelected = $linq(GlobalDataFactory.documentTypes).singleOrDefault(undefined, "x => x.id == " + model.typeSelect);

            if(model.wizard || model.wizard_AllInOne) {
                return true;
            }

            if(typeSelected) {
                return typeSelected.documentTemplateSelection == 'NOT_REQUIRED' && typeSelected.documentFileAttachment == 'NOT_REQUIRED';
            }

            return false;
        }

        factory.selectFile = function(model, files) {
            model.template = { $selected: undefined };

            if(files && files[0]) {
                model.file = files[0];
            } else {
                model.file = undefined;
            }
            
            if (!$rootScope.esetMode && model.inputType == 'file' && model.file && model.file.name && !model.title) {
                model.title = model.file.name.substring(0, model.file.name.lastIndexOf("."));
            }
        }

        factory.setTemplateArchiveClassification = function(model) {
            if(model.modifyArchiveClassification && model.tramArchiveClassification && model.inputType == 'template') {
                var templateSelected = $linq(GlobalDataFactory.templates).singleOrDefault(undefined, "x => x.id == " + Math.abs(item.id));

                if(templateSelected && templateSelected.archiveClassification) {
                    model.archiveClassification.$selected = {
                        id: templateSelected.archiveClassification.id,
                        title: templateSelected.archiveClassification[Language.getActiveColumn()]
                    }
                } else {
                    model.archiveClassification = {};
                }
            }
        }

        factory.setFileArchiveClassification = function(model) {
            if (!model.modifyArchiveClassification && !model.tramArchiveClassification && model.inputType == 'file') {
                var typeSelected = $linq(GlobalDataFactory.documentTypes).singleOrDefault(undefined, "x => x.id == " + model.typeSelect);

                if (typeSelected && typeSelected.archiveClassification) {
                    model.archiveClassification.$selected = {
                        id: typeSelected.archiveClassification.id,
                        title: typeSelected.archiveClassification[Language.getActiveColumn()]
                    }
                } else {
                    model.archiveClassification = {};
                }
            }
        }

        factory.selectTemplate = function(model, item) {
            model.template = { $selected: item };
            model.template.$selected.id = Math.abs(model.template.$selected.id);
            model.file = undefined;

            factory.setTemplateArchiveClassification(model);
        }

        factory.requiredValidator = function(model,title_field) {
            if(model) {
                var typeSelected = $linq(GlobalDataFactory.documentTypes).singleOrDefault(undefined, "x => x.id == " + model.typeSelect);

                if (typeSelected) {
                    title_field.templateOptions.placeholder = typeSelected[Language.getActiveColumn()];

                    if (typeSelected.documentTemplateSelection == 'REQUIRED' && (!model.template || !model.template.$selected || !model.template.$selected.id)) {
                        return false;
                    } else if (typeSelected.documentFileAttachment == 'REQUIRED' && (!model.file || !model.file.name)) {
                        return false;
                    } else if (typeSelected.documentTemplateSelection == 'DOCUMENT_OR_TEMPLATE' && (!model.file || !model.file.name) && (!model.template || !model.template.$selected || !model.template.$selected.id)) {
                        return false;
                    } else if (typeSelected.documentFileAttachment == 'DOCUMENT_OR_TEMPLATE' && (!model.template || !model.template.$selected || !model.template.$selected.id) && (!model.file || !model.file.name)) {
                        return false;
                    }
                } else {
                    title_field.templateOptions.placeholder = '';
                }
            }else{
                return false;
            }
            return true;
        }

        factory.getTemplateOptions = function(model) {
            var clearNode = function(options, optionsToModify) {
                if(optionsToModify && optionsToModify.length > 0) {
                    var optionsAux = {};
                    var modifyOptions = [];
                    var modifyOptionsAux = [];

                    for(var key in options) {
                        modifyOptions = $linq(options[key]).where(function(x) {
                            var contains = $linq(optionsToModify).count(function(y) {
                                if(y == x.id) {
                                    return true;
                                } else {
                                    return false;
                                }
                            });

                            if(contains > 0) {
                                return true;
                            } else {
                                return false;
                            }
                        }).toArray();

                        _.forEach(modifyOptions, function(value, index) {
                            if(options[value.id] && options[value.id].length > 0) {
                                optionsToModify.splice(optionsToModify.indexOf(value.id), 1);
                            } else {
                                modifyOptionsAux.push(index);
                            }
                        });

                        var aux = $linq(options[key]).where(function(x) {
                            var contains = $linq(optionsToModify).count(function(y) {
                                if(y == x.id) {
                                    return true;
                                } else {
                                    return false;
                                }
                            });

                            if(contains > 0) {
                                if((options[x.id] && options[x.id].length > 0) || x.id < 0) {
                                    return true;
                                } else {
                                    return false;
                                }
                            } else {
                                if((!options[x.id] || options[x.id].length == 0) && x.id > 0) {
                                    return false;
                                } else {
                                    return true;
                                }
                            }
                        }).toArray();

                        if(aux && aux.length > 0) {
                            optionsAux[key] = aux;
                        }
                    }

                    return clearNode(optionsAux, modifyOptionsAux);
                } else {
                    return options;
                }
            }

            var templateOptions = $linq(angular.copy(GlobalDataFactory.templateTree)).where(function(x) {
            	var haveProfile = true;
            	if(x.id < 0){
            		if(x.profiles && x.profiles.length > 0){
            			var profiles = $linq(x.profiles).intersect($rootScope.LoggedUser.userProfiles, function(x,y){
                            if(y.profile){
                                return (x.profile.id == y.profile.id);
                            }
                            else{
                                return true;
                            }
                        }).toArray();
                        if(profiles && profiles.length > 0){
                        	haveProfile = true;
                        }
                        else{
                        	haveProfile = false;
                        }
            		}
            	}
            	
                if((haveProfile) && (!x.documentType || x.documentType.id == model.typeSelect)) {
                    return true;
                } else {
                    return false;
                }
            }).toArray();

            var tmpOptions = CommonService.getTreeData(angular.copy(templateOptions), Language.getActiveColumn());
            for(var key in tmpOptions) {
                tmpOptions[key] = $linq(tmpOptions[key]).where(function(x) {
                    if(!x.parent && x.id > 0) {
                        return false;
                    } else {
                        return true;
                    }
                }).toArray();
            }

            var options = {};
            var toFixOptions = [];
            for(var key in tmpOptions) {
                if(tmpOptions[key].length > 0) {
                    options[key] = tmpOptions[key];
                } else {
                    toFixOptions.push(key);
                }
            }
            var optionsToReturn = {};
            if(toFixOptions.length > 0) {
                optionsToReturn = clearNode(options, toFixOptions);
            } else {
                optionsToReturn =  options;
            }
            if(optionsToReturn){
                if (Object.keys(optionsToReturn).length == 0) {
                    optionsToReturn = {0:[]};
                }
            }else{
                optionsToReturn = {0:[]};
            }
            return optionsToReturn;
        }

        //endregion

        //region archiveClassification

        factory.hideArchiveClassification = function (model) {
            if($rootScope.esetMode && model.tramArchiveClassification) {
                if(model.archiveClassification.$selected) {
                    return true;
                } else {
                    return false;
                }
            } else if($rootScope.esetMode && model.archiveClassification.$selected) {
                if(model.modifyArchiveClassification){
                    return false;
                }else{
                    return true;
                }
            } else {
                return false;
            }
        }

        //endregion

        var openSelectMasiveFilesModal = function(preCloseModalFunction, self, archiveClassification, extra, documentType) {
            var addNewDocuments = function () {
                if (this.annexaFormly.form.$valid) {
                    var selfAux = this;
                    var data = selfAux.annexaFormly.model.modal_body;
                    var documentNames = [];
                    if(data && data.file) {
                        _.forEach(data.file, function(index){
                            documentNames.push(index.name);
                        });
                        factory.getDocumentModal(documentNames, self, preCloseModalFunction, archiveClassification, extra, data.file, 'global.buttons.document.news', '&#xe89c', documentType, true);
                    }
                    selfAux.close();
                }
            }

            var modalGetNewDocuments = angular.copy(globalModals.uploadMultiFile);
            modalGetNewDocuments.title = 'global.buttons.document.news';
            modalGetNewDocuments.icon = '&#xe89c';
            modalGetNewDocuments.annexaFormly.model = {};
            modalGetNewDocuments.annexaFormly.model.modal_body = {};
            modalGetNewDocuments.annexaFormly.options = {};
            modalGetNewDocuments.annexaFormly.options.formState = {readOnly: false};
            AnnexaFormlyFactory.showModal('modalUpladaNewDocuments', modalGetNewDocuments, addNewDocuments, false);
        }

        factory.openMasiveDocumentModalFolderFromDoc = function (preCloseModalFunction, self, archiveClassification, extra, documentType) {
            openSelectMasiveFilesModal(preCloseModalFunction, self, archiveClassification, extra, documentType);
        }

        factory.openMasiveDocumentModalFolder = function(preCloseModalFunction, self, archiveClassification, extra, documentType){
            openSelectMasiveFilesModal(preCloseModalFunction, self, archiveClassification, extra, documentType);
        }

        factory.openMasiveDocumentModalScan = function(preCloseModalFunction, self, archiveClassification, extra, documentType){
            factory.getDocumentFromScanner()
                .then(function (data) {
                    factory.getDocumentModal(data, self, preCloseModalFunction, archiveClassification, extra, undefined, undefined, undefined, documentType);
                }).catch(function (error) {
                    //Empty
            });
        }

        factory.validDocumentName = function (title, type) {
            var daysInMonth = function (month, year) {
                return new Date(year, month, 0).getDate();
            }

            if($rootScope.esetMode || ($rootScope.app &&  $rootScope.app.configuration &&  $rootScope.app.configuration.validate_title_document && $rootScope.app.configuration.validate_title_document.value)) {
                if(title) {
                    var selectedType = $linq(GlobalDataFactory.allDocumentTypes).singleOrDefault(undefined, "x => x.id == " + type);

                    if (selectedType) {
                        if(title.startsWith(selectedType.acronym)) {
                            if(title.length >= 8) {
                                var docDate = title.slice(-8);
                                var day = docDate.substring(0, 2);
                                var month = docDate.substring(2, 4);
                                var year = docDate.substring(4);

                                if(!isNaN(day) && !isNaN(month) && !isNaN(year)) {
                                    var iDay = parseInt(day);
                                    var iMonth = parseInt(month);
                                    var iYear = parseInt(year);

                                    if(iDay >= 1 && iDay <= 31 && iMonth >= 1 && iMonth <= 12 && iYear >= 1970) {
                                        if(iDay <= daysInMonth(iMonth, iYear)) {
                                            return true;
                                        } else {
                                            return false;
                                        }
                                    } else {
                                        return false;
                                    }
                                } else {
                                    return false;
                                }
                            } else {
                                return false;
                            }
                        } else {
                            return false;
                        }
                    } else {
                        return false;
                    }
                } else {
                    return false;
                }
            } else {
                return true
            }

        }

        factory.getDocumentModal = function(data, self, preCloseFunction, archiveClassification, transaction, files, modalTitle, modalIcon, documentType, hideStep1Title){
            var finishFromScannerWizard = function() {
                $rootScope.$broadcast('fromScannerUploadDocuments', { modal: this });
            }

            var modal = angular.copy(globalModals.fromScannerWizard);
            if(hideStep1Title) {
                modal.wizard.steps[0].title = '';
            }
            if(modalTitle){
                modal.title = modalTitle;
            }
            if(modalIcon){
                modal.icon = modalIcon;
            }
            if(files && files.length > 0){
                modal.wizard.filesToUpload = files;
            }
            _.forEach(modal.wizard.steps, function (step, key) {
                modal.wizard.steps[key].annexaFormly.model = {};
                modal.wizard.steps[key].annexaFormly.options = {};
            });
            var titleAll = $filter("translate")("global.documents.uploadDocuments");
            var newDocumentModalAll = factory.getOpenNewDocumentModal(undefined, undefined, self.module, titleAll, undefined, archiveClassification, documentType, transaction, undefined, true);
            newDocumentModalAll.annexaFormly.model.modal_body.fileName = titleAll;
            modal.wizard.steps.push({
                title: titleAll,
                key: 'documentAllInOne',
                disabled: true,
                annexaFormly: newDocumentModalAll.annexaFormly,
                exitValidation: function (model, step, wizard) {
                    var indexNextStep = $linq(wizard.steps).where("x => !x.disabled").indexOf(function (x) { return (x.key == step.key); });
                    if (indexNextStep && indexNextStep > -1 && WizardHandler.wizard('modal-wizard').currentStepNumber() == (indexNextStep + 1)) {
                        if (step.annexaFormly.form) {
                            step.annexaFormly.form.$setSubmitted();

                            if (!step.annexaFormly.form.$valid) {
                                return false;
                            }
                        }
                    } else {
                        return false;
                    }
                },
                beforeNext: function (step, wizard) {
                    var indexStep = $linq(wizard.steps).indexOf(function (x) { return (x.key == step.key); });
                    if (indexStep != -1 && indexStep + 1 < wizard.steps.length) {
                        var documentsSelected = $linq(Object.keys(wizard.steps[0].annexaFormly.model.documents)).where(function(x){
                            if(wizard.steps[0].annexaFormly.model.documents[x]){
                                return true;
                            }else{
                                return false;
                            }
                        }).toArray();

                        var scannerDocuments = [];

                        _.forEach(documentsSelected, function (doc) {
                            var model = angular.copy(wizard.steps[indexStep].annexaFormly.model.modal_body);
                            model.title = doc;
                            model.fileName = doc;
                            model.procState = 'pending';
                            model.error = '';
                            if(wizard.filesToUpload && wizard.filesToUpload.length > 0){
                                var indexFile = $linq(wizard.filesToUpload).indexOf(function (x) { return (x.name == doc); });

                                if(indexFile != -1) {
                                    model.file = wizard.filesToUpload[indexFile];
                                }
                            }
                            scannerDocuments.push(model);
                        });

                        wizard.steps[wizard.steps.length - 1].annexaFormly.fields[0].data.documents = scannerDocuments;
                        wizard.steps[wizard.steps.length - 1].annexaFormly.fields[0].data.files = wizard.filesToUpload;
                    }
                }
            });
            _.forEach(data, function (item) {
                var newDocumentModal = factory.getOpenNewDocumentModal(undefined, undefined, self.module, item, undefined, archiveClassification, documentType, transaction, modal.wizard.steps.length);
                newDocumentModal.annexaFormly.model['modal_body_' + modal.wizard.steps.length].fileName = item;
                var currentStep = modal.wizard.steps.length;
                modal.wizard.steps.push({
                    title: item,
                    key: item,
                    disabled: true,
                    annexaFormly: newDocumentModal.annexaFormly,
                    exitValidation: function (model, step, wizard) {
                        var indexNextStep = $linq(wizard.steps).where("x => !x.disabled").indexOf(function (x) { return (x.key == step.key); });
                        if (indexNextStep && indexNextStep > -1 && WizardHandler.wizard('modal-wizard').currentStepNumber() == (indexNextStep + 1)) {
                            if (step.annexaFormly.form) {
                                step.annexaFormly.form.$setSubmitted();

                                if (!step.annexaFormly.form.$valid) {
                                    return false;
                                }

                                if (!modal.isLastStep && !factory.validDocumentName(step.annexaFormly.model['modal_body_' + currentStep].title, step.annexaFormly.model['modal_body_' + currentStep].typeSelect)) {
                                    if ($linq(modal.alerts).count("x => x.msg == 'global.errors.docNameFormat'") == 0) {
                                        modal.alerts.push({msg: 'global.errors.docNameFormat'});
                                    }
                                    return false;
                                } else {
                                    var indexAlert = $linq(modal.alerts).indexOf("x => x.msg == 'global.errors.docNameFormat'");

                                    if (indexAlert != -1) {
                                        modal.alerts.splice(indexAlert, 1);
                                    }
                                }
                            }
                        } else {
                            return false;
                        }
                    },
                    beforeNext: function (step, wizard) {
                        var indexStep = $linq(wizard.steps).indexOf(function (x) { return (x.key == step.key); });
                        if (indexStep != -1 && indexStep + 1 < wizard.steps.length) {
                            var lastStep = false;

                            if (indexStep == wizard.steps.length - 2) {
                                lastStep = true;
                            } else {
                                var penidngDocuments = $linq(wizard.steps).skip(indexStep + 1).where(function (x) {
                                    if (_.contains(Object.keys(wizard.steps[0].annexaFormly.model.documents), x.key) && !x.disabled) {
                                        return true;
                                    }

                                    return false;
                                }).toArray();

                                if (penidngDocuments.length == 0) {
                                    lastStep = true;
                                }
                            }
                            modal.isLastStep = lastStep;
                            if (lastStep) {
                                var selectedDocuments = $linq(wizard.steps).where(function (x) {
                                    if (_.contains(Object.keys(wizard.steps[0].annexaFormly.model.documents), x.key) && !x.disabled) {
                                        return true;
                                    }

                                    return false;
                                }).toArray();

                                var scannerDocuments = [];

                                _.forEach(selectedDocuments, function (doc) {
                                    var indexDoc = $linq(wizard.steps).indexOf(function (x) { return (x.key == doc.key); });

                                    if (indexDoc != -1) {
                                        var model = doc.annexaFormly.model['modal_body_' + indexDoc];
                                        model.procState = 'pending';
                                        model.error = '';
                                        if(wizard.filesToUpload && wizard.filesToUpload.length > 0){
                                            var indexFile = $linq(wizard.filesToUpload).indexOf(function (x) { return (x.name == doc.key); });

                                            if(indexFile != -1) {
                                                model.file = wizard.filesToUpload[indexFile];
                                            }
                                        }
                                        scannerDocuments.push(model);
                                    }
                                });

                                wizard.steps[wizard.steps.length - 1].annexaFormly.fields[0].data.documents = scannerDocuments;
                            }
                        }
                    }
                });
            });
            modal.wizard.steps.push({
                title: '',
                key: 'uploadingDocuments',
                disabled: false,
                disableNext: function () {
                    return this.annexaFormly.form.$invalid;
                },
                afterNext: function (step, wizard) {
                    modal.isLastStep = false;
                },
                annexaFormly: {
                    fields: [
                        {
                            key: 'upload',
                            type: 'annexaComponent',
                            templateOptions: {
                                type: 'annexa-scanner-documents'
                            },
                            controller: ['$scope', function ($scope) {
                                var a = 0;
                            }],
                            data: {
                                documents: [],
                                module: self.module,
                                rowClass: 'm-b-xs row',
                                labelStrong: true
                            }
                        }
                    ]
                }
            })
            var selectableDocuments = [];

            modal.wizard.steps[0].annexaFormly.model.documents = {};
            _.forEach(data, function (item) {
                selectableDocuments.push({ id: item, value: item });
                modal.wizard.steps[0].annexaFormly.model.documents[item] = (files && files.length > 0)? true : false;
            });

            if(files && files.length > 0){
                modal.wizard.steps[0].annexaFormly.model.isUploadDocuments = true;
            }

            modal.wizard.steps[0].annexaFormly.fields[1].templateOptions.options = selectableDocuments;

            modal.wizard.modal = modal;
            modal.preClose = preCloseFunction;

            AnnexaFormlyFactory.showModal('fromScannerWizardModal', modal, finishFromScannerWizard, false, true)
        }

        factory.getOpenNewDocumentModal = function (tableDocs, commitFunction, module, title, folder, archiveClassification, typeToSelect, extra, wizard_step, wizard_AllInOne, documentTypesAux) {
            var modal = angular.copy(globalModals.documentNew);

            if(wizard_step) {
                modal.annexaFormly.fields[0].key += '_' + wizard_step;
            }

            var modal_body_key = modal.annexaFormly.fields[0].key;

            if(title && title.lastIndexOf(".") != -1) {
            	title = title.substring(0, title.lastIndexOf("."));
            }

            var typeSelect = {};
            var inputType = 'file';
            if(documentTypesAux && documentTypesAux.length > 0){
                typeSelect = documentTypesAux[0];
                inputType = 'template';
                modal.annexaFormly.fields[0].fieldGroup[0].templateOptions.optionsAux = documentTypesAux;
            }else{
	            if(GlobalDataFactory.documentTypes && GlobalDataFactory.documentTypes.length > 0) {
	                if(!typeToSelect) {
	                    if ($rootScope.app.configuration && $rootScope.app.configuration.sec_document_types && $rootScope.app.configuration.sec_document_types.SPR.id
	                    		&& extra && extra.transactionType && extra.transactionType.transactionSubtype == apiAdmin.transactionSubtypes[1].id) {
	                    	factory.typeDefault = $linq(GlobalDataFactory.documentTypes).singleOrDefault(undefined, "x => x.id == " + $rootScope.app.configuration.sec_document_types.SPR.id[0]);
	                	} else if ($rootScope.app.configuration && $rootScope.app.configuration.sec_document_types && $rootScope.app.configuration.sec_document_types.STR.id
	                			&& extra && extra.transactionType && extra.transactionType.transactionSubtype == apiAdmin.transactionSubtypes[3].id) {
	                		factory.typeDefault = $linq(GlobalDataFactory.documentTypes).singleOrDefault(undefined, "x => x.id == " + $rootScope.app.configuration.sec_document_types.STR.id);
	                	} else if ($rootScope.app.configuration && $rootScope.app.configuration.general_document_type && $rootScope.app.configuration.general_document_type.value) {
	                        factory.typeDefault = $linq(GlobalDataFactory.documentTypes).singleOrDefault(undefined, "x => x.id == " + $rootScope.app.configuration.general_document_type.value);
	                    }
	                }
	
	                typeSelect = typeToSelect ? typeToSelect : (factory.typeDefault ? factory.typeDefault : GlobalDataFactory.documentTypes[0]);
	                inputType = typeSelect.documentFileAttachment != 'NOT_REQUIRED' ? 'file' : (typeSelect.documentTemplateSelection != 'NOT_REQUIRED' ? 'template' : 'file');
	            }
            }

            modal.annexaFormly.model = {};
            var profileAux = HelperService.getDefultProfileFor('DOC', module, factory.profilesUser, $rootScope.LoggedUser.userDefaultEntityProfiles, extra);
            var haveOneProfile = HelperService.getOneProfileFor('DOC', module, factory.profilesUser, $rootScope.LoggedUser.userDefaultEntityProfiles, extra);
            modal.annexaFormly.model[modal_body_key] = {
                typeSelect:  typeSelect.id,
                template: {},
                title: (($rootScope.esetMode)? '' : ((title && title.length > 199) ? title.substring(0, 199) : title)),
                dateDocumentCreation: new Date(),
                archiveClassification: HelperService.treeSelected(archiveClassification) ? archiveClassification.model : {},
                section: factory.sectionsUser.length == 1 ? factory.sectionsUser[0].id : undefined,
                profiles: ((module != 'EXPED')?((profileAux)?[profileAux]:[]) : ((haveOneProfile)?[profileAux]:[])),
                module: module,
                extra: extra,
                showFile: true,
                showTemplate: !($rootScope.esetMode && (module == 'REGENTRADA' || module == 'REGSALIDA')) ? true : false,
                inputType:inputType,
                add_document_type_and_creation_date_to_document_name: false,
                tramArchiveClassification: HelperService.treeSelected(archiveClassification),
                modifyArchiveClassification: false,
                oneProfile: haveOneProfile,
                oneSection: factory.sectionsUser.length == 1,
                wizard: (wizard_step) ? true : false,
                wizard_AllInOne: (wizard_AllInOne) ? true : false
            };

            if(!modal.annexaFormly.model[modal_body_key].modifyArchiveClassification && !modal.annexaFormly.model[modal_body_key].tramArchiveClassification && modal.annexaFormly.model[modal_body_key].inputType == 'file') {
                if(typeSelect && typeSelect.archiveClassification) {
                    modal.annexaFormly.model[modal_body_key].archiveClassification.$selected = { id: typeSelect.archiveClassification.id, title: typeSelect.archiveClassification[Language.getActiveColumn()] };
                }
            }

            modal.annexaFormly.options = {};
            modal.annexaFormly.options.formState = { readOnly: false};
            modal.module = module ? module : 'DOC';
            modal.extra = tableDocs ? tableDocs : undefined;
            modal.commitFunction = commitFunction ? commitFunction : undefined;
            modal.folder = folder;

            //region Camps Personalitzat

            if(documentTypesAux && documentTypesAux.length > 0){
            	 _.forEach(documentTypesAux, function (type) {
	            	var custom_fields = $linq(type.customFields).orderBy("x => x.viewOrder").toArray();
	            	
	                _.forEach(custom_fields, function(field) {
	                    var form = new AnnexaFormly();
	                    form = CustomFieldFactory.addFormlyFieldRow(form, field.customField, field.required, type.id + '_' + field.customField.id);
	                    form.fields[0].className = 'col-sm-12';
	                    form.fields[0].hideExpression = function ($viewValue, $modelValue, scope) {
	                        if(!field.noEditable) {
	                            if (type.id != scope.model.typeSelect) {
	                                scope.model['cf_' + type.id + '_' + field.customField.id] = CustomFieldFactory.calculateValueCustomField(field, modal.annexaFormly.model.modal_body, ('cf_' + type.id + '_' + field.customField.id));
	                            }
	
	                            return !(type.id == scope.model.typeSelect);
	                        }else {
	                            return true;
	                        }
	                    }
	                    modal.annexaFormly.fields[0].fieldGroup.push(form.fields[0]);
	                    modal.annexaFormly.model[modal_body_key]['cf_' + type.id + '_' + field.customField.id] = CustomFieldFactory.calculateValueCustomField(field, modal.annexaFormly.model.modal_body, ('cf_' + type.id + '_' + field.customField.id));
	                });
            	 });
            }else{
	            _.forEach(GlobalDataFactory.documentTypes, function (type) {
	                var custom_fields = $linq(type.customFields).orderBy("x => x.viewOrder").toArray();
	
	                _.forEach(custom_fields, function(field) {
	                    var form = new AnnexaFormly();
	                    form = CustomFieldFactory.addFormlyFieldRow(form, field.customField, field.required, type.id + '_' + field.customField.id);
	                    form.fields[0].className = 'col-sm-12';
	                    form.fields[0].hideExpression = function ($viewValue, $modelValue, scope) {
	                        if(!field.noEditable) {
	                            if (type.id != scope.model.typeSelect) {
	                                scope.model['cf_' + type.id + '_' + field.customField.id] = CustomFieldFactory.calculateValueCustomField(field, modal.annexaFormly.model.modal_body, ('cf_' + type.id + '_' + field.customField.id));
	                            }
	
	                            return !(type.id == scope.model.typeSelect);
	                        }else {
	                            return true;
	                        }
	                    }
	                    modal.annexaFormly.fields[0].fieldGroup.push(form.fields[0]);
	                    modal.annexaFormly.model[modal_body_key]['cf_' + type.id + '_' + field.customField.id] = CustomFieldFactory.calculateValueCustomField(field, modal.annexaFormly.model.modal_body, ('cf_' + type.id + '_' + field.customField.id));
	                });
	            });
            }
            //endregion

            return modal;
        }

        factory.openNewDocument = function (tableDocs, commitFunction, module, title, folder, archiveClassification, typeToSelect, extra, documentTypes, relatedDocumentId){
            var modal = factory.getOpenNewDocumentModal(tableDocs, commitFunction, module, title, folder, archiveClassification, typeToSelect, extra, undefined, undefined, documentTypes, relatedDocumentId);
            if(modal && relatedDocumentId){
            	modal.relatedDocumentId = relatedDocumentId;
            }
            AnnexaFormlyFactory.showModal('modalDocumentNew', modal, factory.saveNewDocument, false, undefined);
        };

        factory.saveNewDocument = function(){
            if (this.annexaFormly.form.$valid) {
                var self = this;
                var model = this.annexaFormly.model.modal_body;

                factory.createNewDocument(model, self.module, self.folder, self.relatedDocumentId)
                    .then(function(data) {
                        if(self.folder) {
                            $rootScope.$broadcast('documentsViewModeChange', { id: self.folder.documentViewModeType.id, selected: self.folder });
                        }

                        if(self.extra) {
                            self.extra.reloadInternalData(true);
                        } else if (self.commitFunction) {
                            self.commitFunction(data);
                        } else {
                            $state.transitionTo('annexa.doc.documents.view', {"code": data.id});
                        }

                        self.close();
                    }).catch(function(error) {
                        self.alerts.push(error)
                });
            }
        };

        factory.createNewDocument = function(model, module, folder, relatedDocumentId) {
            var deferred = $q.defer();

            var moduleDoc = 'DOC';

            if(factory.validDocumentName(model.title, model.typeSelect)) {
                if (module) {
                    moduleDoc = module;
                }

                var document = {
                    createdDate: new Date(),
                    modifyDate: new Date(),
                    dateLastAccess: new Date(),
                    urgent: false,
                    favorite: false,
                    downloaded: false,
                    sentMail: false,
                    type: {id: model.typeSelect},
                    section: {id: model.section},
                    profiles: [],
                    module: moduleDoc,
                    dateDocumentCreation: model.dateDocumentCreation
                };

                _.forEach(model.profiles, function(item) {
                    document.profiles.push({ profile: { id: item } });
                });
                if(document.profiles.length == 0 && model.profile){
                    document.profiles.push({ profile: { id: model.profile } });
                }
                if (model.archiveClassification && model.archiveClassification.$selected && model.archiveClassification.$selected.id) {
                    document.archiveClassification = { id: model.archiveClassification.$selected.id };
                }

                if (model.template && model.template.$selected && model.template.$selected.id) {
                    document.template = { id: model.template.$selected.id };
                }
                var existName = false;

                if (model.title) {
                    document.name = model.title;
                    existName = true;
                } else {
                    var selectedType = $linq(GlobalDataFactory.documentTypes).singleOrDefault(undefined, "x => x.id == "+model.typeSelect);

                    document.name = selectedType ? selectedType[Language.getActiveColumn()] : '';
                }
                document.sentMail = existName;

                if (model.inputType == 'file') {
                    if(!document.name && model.file) {
                        document.name = model.file.fileName;
                    } else if(!document.name && model.fileName) {
                        document.fileName = model.fileName;
                        document.name = model.fileName;
                    } else if(model.fileName) {
                        document.fileName = model.fileName;
                    }
                }

                if (model.inputType == 'template') {
                    if (model.template && model.template.id) {
                        var aux = HelperService.findFirstElementWhere(GlobalDataFactory.templateTree, 'id', model.template.id, -1);
                        if (aux != -1) {
                            document.template = {id: aux.idOriginal};
                        }
                    }
                }

                var cf = CustomFieldFactory.getModelValues(model);

                document.customFields = [];

                var documentType = $linq(GlobalDataFactory.documentTypes).singleOrDefault(undefined, "x => x.id == " + document.type.id);

                if(documentType && cf && cf.length > 0) {
                    _.forEach(documentType.customFields, function(docTypeField) {
                        var docCustomField = {
                            customField: { id: docTypeField.customField.id },
                            required: docTypeField.required,
                            viewOrder: docTypeField.viewOrder,
                            value: docTypeField.value,
                            noEditable: docTypeField.noEditable
                        };

                        var cfValue = $linq(cf).singleOrDefault(undefined, "x => x.id == 'cf_" + documentType.id  + "_" + docTypeField.customField.id + "'");

                        if(cfValue) {
                            if(docTypeField.customField.frontendType == 'CHECKBOX'){
                                var custom_field_selected = $linq(cf).where(function(x){
                                    if(x.id.startsWith(cfValue.id+"_")){return true}else{return false}}
                                ).toArray();
                                if(custom_field_selected && custom_field_selected.length > 0){
                                    docCustomField.value = '[';
                                    _.forEach(custom_field_selected, function (item, key) {
                                        if(item.value) {
                                            if(docTypeField.customField && docTypeField.customField.listValues && docTypeField.customField.listValues.length > 0) {
                                                var custom_field_value_selected = $linq(docTypeField.customField.listValues).where(function(x){
                                                    if(item.id.endsWith("_"+x.value)){return true}else{return false}}
                                                ).toArray();
                                                if(custom_field_value_selected && custom_field_value_selected.length > 0){
                                                    docCustomField.value += ((docCustomField.value == '[') ?custom_field_value_selected[0].value : ',' + custom_field_value_selected[0].value);
                                                }
                                            }
                                        }
                                    });
                                    docCustomField.value += ']';
                                }
                            }else if(Array.isArray(cfValue.value)) {
                                if(docTypeField.customField.frontendType == 'MULTIPLESELECT') {
                                    docCustomField.value = '[';

                                    _.forEach(cfValue.value, function (item) {
                                        docCustomField.value += ((docCustomField.value == '[') ? item : ',' + item);
                                    });

                                    docCustomField.value += ']';
                                } else {
                                    docCustomField.value = cfValue.value[0];
                                }
                            } else {
                                if(cfValue.value instanceof Date) {
                                    docCustomField.value = $filter('date')(cfValue.value,'yyyy-MM-ddTHH:mm:ss');
                                } else {
                                    docCustomField.value = cfValue.value;
                                }
                            }
                        }

                        document.customFields.push(docCustomField);
                    })
                }

                document.csv = Language.getActiveColumn();
                if(relatedDocumentId && relatedDocumentId > 0){
                	$http({
                        method: 'POST',
                        url: './api/doc/generate_from_content/' + relatedDocumentId,
                        data: JSOG.encode(document)
                    }).success(function(data, status) {
                        deferred.resolve(JSOG.decode(data));
                    }).error(function(msg,code) {
                        deferred.reject(msg);
                    });

                }else if(model && model.templateDefaultBooks && model.templateDefaultBooks.$selected){
                	deferred.resolve(document);
                }else{
	                RestService.insertMultiPartData('./api/doc/with_files', document, model.file)
	                    .then(function(data) {
	                        deferred.resolve(JSOG.decode(data));
	                    }).catch(function(error) {
	                    if (error && error.message && error.message.toUpperCase().indexOf('ALFRESCO') != -1) {
	                        deferred.reject({msg: 'global.literals.errorSaveGD'});
	                    } else {
	                        deferred.reject({ msg: 'global.literals.errorNewDoc' })
	                    }
	                });
                }
            } else {
                deferred.reject({ msg: 'global.errors.docNameFormat' });
            }

            return deferred.promise;
        }

        //endregion

        //region General (OK, Repasat)

        factory.updateDocument = function(document){
            var deferred = $q.defer();

            $http({
                method: 'PUT',
                url: './api/doc/' + document.id,
                data: JSOG.encode(document)
            }).success(function(data, status) {
                deferred.resolve(JSOG.decode(data));
            }).error(function(msg,code) {
                deferred.reject(msg);
            });

            return deferred.promise;
        };

        factory.deleteDocument = function (id) {
            var deferred = $q.defer();

            $http({
                url: './api/doc/' + id,
                method: 'DELETE'
            }).then(function () {
                deferred.resolve();
            }).catch(function(error) {
                deferred.reject(error);
            });

            return deferred.promise;
        }

        factory.updateDocumentCustomField = function(customField){
            var deferred = $q.defer();

            $http({
                method: 'PUT',
                url: './api/doc/' + customField.document.id + '/custom_field',
                data: JSOG.encode(customField)
            }).success(function(data, status) {
                deferred.resolve(JSOG.decode(data));
            }).error(function(msg,code) {
                deferred.reject(msg);
            });

            return deferred.promise;
        };

        factory.getDocumentFromScanner = function () {
            var deferred = $q.defer();

            $http({
                url: './api/doc/scanner',
                method: 'GET'
            }).then(function(data) {
                deferred.resolve(JSOG.decode(data.data));
            }).catch(function(error) {
                deferred.reject(error);
            })

            return deferred.promise;
        }

        factory.updateDocumentFile = function(files, id) {
            var deferred = $q.defer();

            var formData = new FormData();
            if(Array.isArray(files)){
            	_.forEach(files, function(file, index){
            		formData.append('files', file);
            	});
            }else{
            	formData.append('files', files);
            }
            $http({
                url: './api/doc/' + id + '/filemultipartfile',
                method: 'PUT',
                headers: {'Content-Type': undefined},
                data: formData,
                transformRequest: function(data, headersGetterFunction) {
                    return data;
                }
            }).then(function (data) {
                deferred.resolve(JSOG.decode(data.data));
            }).catch(function (error) {
                deferred.reject(error);
            });
            return deferred.promise;
        }

        factory.addAttachments = function(attachments, id) {
            var deferred = $q.defer();
            
            var formData = new FormData();
            if(Array.isArray(attachments)){
            	_.forEach(attachments, function(file, index){
            		formData.append('files', file);
            	});
            }else{
            	formData.append('files', attachments);
            }
            $http({
                method: 'POST',
                url: './api/doc/' + id + '/attachmentmultipartfile',
                headers: {'Content-Type': undefined},
                data: formData,
                transformRequest: function(data, headersGetterFunction) {
                    return data;
                }
            }).then(function(data) {
                deferred.resolve(JSOG.decode(data.data));
            }).catch(function (error) {
                deferred.reject(error);
            });

            return deferred.promise;
        }

        factory.deleteAttachment = function(id) {
            var deferred = $q.defer();

            $http({
                method: 'DELETE',
                url: './api/doc/attachment/' + id
            }).success(function(data, status) {
                deferred.resolve(JSOG.decode(data));
            }).error(function(msg,code) {
                deferred.reject(msg);
            });

            return deferred.promise;
        }

        factory.getAttachments = function(id) {
            var deferred = $q.defer();

            $http({
                method: 'GET',
                url: './api/doc/'+ id +'/attachment'
            }).success(function(data, status) {
                deferred.resolve(JSOG.decode(data));
            }).error(function(msg,code) {
                deferred.reject(msg);
            });

            return deferred.promise;
        }

        factory.getTemporalDocumentURL = function(id, mimetype, fileName) {
            var deferred = $q.defer();

            $http({
                method: 'POST',
                url: './api/repodocs/' + window.btoa(id) + '/temp_url',
                data: JSON.stringify({ mimetype: mimetype, fileName: fileName }),
                responseType: "arraybuffer"
            }).then(function(response) {
                if(response.headers && response.headers()['view_or_download']) {
                    deferred.resolve({ type: response.headers()['view_or_download'], data: response.headers()['url_view'] ? response.headers()['url_view'] : response.data, contentType: response.headers()['content-type'] });
                } else {
                    deferred.reject(response);
                }
            }).catch(function(error) {
                deferred.reject(error);
            })

            return deferred.promise;
        }

        factory.definitiveDocument = function(document){
            var deferrend = $q.defer();
            $http({
                method: 'PUT',
                url: './api/doc/' + document.id + '/definitive',
                data: JSOG.encode(document)
            }).success(function(data, status) {
                deferrend.resolve(JSOG.decode(data));
            }).error(function(msg,code) {
                deferrend.reject(msg);
            });
            return deferrend.promise;
        };

        factory.definitiveDocuments = function(documents){
            var deferrend = $q.defer();

            var ids = '';

            _.forEach(documents, function (item) {
                ids += (ids != '' ? ',' : '') + item;
            });

            $http({
                method: 'PUT',
                url: './api/doc/' + ids + '/definitives',
                data: JSOG.encode(documents)
            }).success(function(data, status) {
                deferrend.resolve(JSOG.decode(data));
            }).error(function(msg,code) {
                deferrend.reject(msg);
            });
            return deferrend.promise;
        };

        factory.returnToDraftDocument = function(id){
            var deferrend = $q.defer();
            $http({
                url: './api/doc/' + id + '/draft',
                method: "PUT"
            }).success(function (res) {
                deferrend.resolve(res);
            }).error(function(msg,code){
                deferrend.reject(msg);
            });
            return deferrend.promise;
        }

        factory.getDocContent = function(id, printDocument) {
            var deferred = $q.defer();

            $http({
                method: 'GET',
                url: './api/doc/' + id + '/content'+((printDocument)?'/'+printDocument:'/false')
            }).then(function(data) {
                deferred.resolve(data.data.url);
            }).catch(function(error) {
                deferred.reject(error);
            });

            return deferred.promise;
        }
        
        factory.getAuthCopyContent = function(id) {
            var deferred = $q.defer();

            $http({
                method: 'GET',
                url: './api/doc/' + id + '/authCopy/true'
            }).then(function(data) {
                deferred.resolve(data.data.url);
            }).catch(function(error) {
                deferred.reject(error);
            });

            return deferred.promise;
        }

        factory.getScanDocumentContent = function(id) {
            var deferred = $q.defer();

            $http({
                method: 'GET',
                url: './api/doc/scanner/' + id + '/content'
            }).then(function(data) {
                deferred.resolve(data.data.url);
            }).catch(function(error) {
                deferred.reject(error);
            });

            return deferred.promise;
        }

        factory.updateFavorite = function (id, favorite) {
            var deferrend = $q.defer();

            $http({
                url: './api/doc/' + id + '/favorite/'+favorite,
                method: "PUT"
            }).then(function() {
                deferrend.resolve(true);
            }).catch(function(error) {
                deferrend.reject(error);
            });

            return deferrend.promise;
        }

        factory.getEditOnlineUrl = function(code,type) {
            var deferrend = $q.defer();
            $http({
                url: './api/repodocs/editUrl',
                method: "GET",
                params: {
                    id: code
                }
            }).success(function (res) {
            	$rootScope.$broadcast('annexaDocumentAuditExecute', {id:res.id});
                switch (type) {
                    case 'microsoft':
                        deferrend.resolve(res.editOnlineUrl);
                        break;
                    case 'libreoffice':
                        deferrend.resolve(res.editOnlineUrlLO);
                        break;
                    default:
                        deferrend.resolve(res.editOnlineUrl);
                        break;
                }
            }).error(function(msg,code){
                deferrend.reject(msg);
            });
            return deferrend.promise;
        };

        factory.canEdit = function(document) {
            if(document && document.docStatus) {
                if(!_.contains(['NEW', 'NEW_AND_ATTACHMENT'], document.docStatus)) {
                	if (_.contains(['PENDING_SIGN'], document.docStatus) && 
                			$rootScope.app.configuration.can_edit_doc_pending_sign && 
                			$rootScope.app.configuration.can_edit_doc_pending_sign.value) {
                    	return true;
                	} else {
                    	return false;
                	}
                }
            }

            return true
        }
        
        factory.canEditTitle = function(canEditDocument) {
        	if (canEditDocument) {
        		return true;
        	} else if ($rootScope.app.configuration.can_always_edit_doc_title && 
        			$rootScope.app.configuration.can_always_edit_doc_title.value){
        		return true;
        	}
        	
        	return false;
        }
        
        factory.callCloudSignAndorra = function(idRowToSign, documentId, locationPrefix, nrt, description) {
            var deferrend = $q.defer();
            $http({
            	method: 'POST',
            	url: './api/repodocs/callCloudSignAndorra',            		
            	data: JSON.stringify({ idRowToSign: idRowToSign, documentId: documentId, locationPrefix: locationPrefix, nrt: nrt, description: description })
            }).success(function (res) {
            	deferrend.resolve(res);
            }).error(function(msg,code){
                deferrend.reject(msg);
            });
            return deferrend.promise;
        };
        
        factory.callfinishCloudSignAndorra = function(documentId, url) {
            var deferrend = $q.defer();
            $http({
            	method: 'POST',
            	url: './api/repodocs/callfinishCloudSignAndorra',            		
            	data: JSON.stringify({ documentId: documentId, url: url })
            }).success(function (res) {
            	deferrend.resolve(res.url);
            }).error(function(msg,code){
                deferrend.reject(msg);
            });
            return deferrend.promise;
        };

        factory.newRelatedDocument = function(id, newRelatedDocument){
        	 var deferred = $q.defer();

             $http({
                 url: './api/doc/' + id + '/documents',
                 method: 'POST',
                 data: JSOG.encode(newRelatedDocument)
             }).then(function(data) {
                 deferred.resolve(JSOG.decode(data.data))
             }).catch(function(error) {
                 deferred.reject(error);
             });

             return deferred.promise;
        }
        
    	factory.deleteRelatedDocument = function(id, removedId){
    		 var deferred = $q.defer();

             $http({
                 url: './api/doc/' + id + '/documents/' + removedId,
                 method: 'DELETE'
             }).then(function (data) {
                 deferred.resolve(data);
             }).catch(function (error) {
                 deferred.reject(error);
             });

             return deferred.promise;
    	}
        //endregion

        return factory;
    }]);